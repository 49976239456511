import React, { useState, useEffect } from "react";
import { post } from 'aws-amplify/api';
import { Link } from "react-router-dom";

const RSVP = () => {
  const [fullName, setFullName] = useState("");
  const [plusOneName, setPlusOneName] = useState("");
  const [attending, setAttending] = useState([]);
  const [numGuests, setNumGuests] = useState(0);
  const [dietaryReqs, setDietaryReqs] = useState([]);
  const [otherDietaryReqs, setOtherDietaryReqs] = useState("");
  const [rsvpSubmitted, setRSVPSubmitted] = useState(false);
  const [rsvpSuccess, setRSVPSuccess] = useState(false);
  const [rsvp, setRSVP] = useState("");

  useEffect(() => {
    const rsvpSubmittedAlready = localStorage.getItem('rsvpSubmitted');
    if (rsvpSubmittedAlready) {
      setRSVPSubmitted(true);
    };
    const rsvpSuccessAlready = localStorage.getItem('rsvpSuccess');
    if (rsvpSuccessAlready) {
      if (rsvpSuccessAlready === "true") {
        setRSVPSuccess(true);
      } else {
        setRSVPSuccess(false);
      }
    }
  }, [])

  useEffect(() => {
    if (rsvp === "true" && fullName) {
      if (plusOneName) {
        setNumGuests(prev => Math.max(2, prev));
      } else {
        setNumGuests(prev => Math.max(1, prev));
      }
    } else {
      setNumGuests(0);
    }
  }, [rsvp, fullName, plusOneName])

  const clearRSVPSubmitted = () => {
    localStorage.removeItem('rsvpSubmitted')
    setRSVPSubmitted(false);
    setDefaultOptions();
  }

  const setDefaultOptions = () => {
    setFullName("");
    setPlusOneName("");
    setAttending([]);
    setNumGuests(0);
    setDietaryReqs([]);
    setOtherDietaryReqs("");
    setRSVP("");
    setRSVPSuccess(false);
    const rsvpYes = document.getElementById("rsvpYes")
    if (rsvpYes) {
      document.getElementById("rsvpYes").selected = false
    }
    const rsvpNo = document.getElementById("rsvpNo");
    if (rsvpNo) {
      document.getElementById("rsvpNo").selected = false;
    }
  }

  async function submitRSVP(e) {
    e.preventDefault();
    
    setRSVPSubmitted(true);
    localStorage.setItem('rsvpSubmitted', 'true');

    try {
      const restOperation = await post({
        apiName: 'weddingRSVPAppApi',
        path: '/rsvp', 
        options: {
          body: {
            full_name: fullName,
            partner_full_name: plusOneName ? plusOneName : "None",
            attending: rsvp === "true" ? attending : ["None"],
            number_of_guests: rsvp === "true" ? plusOneName != "None" ? Math.max(numGuests, 2) :  numGuests : 0,
            dietary_requirements: rsvp === "true" ? dietaryReqs == "Other" ? [otherDietaryReqs] : dietaryReqs : ["None"]
          }
        }
      });
      setRSVPSuccess(true);
      localStorage.setItem('rsvpSuccess', 'true');

      const { statusCode, body } = await restOperation.response;
      const response = await body.json();

      if ( statusCode !== 200 ) {
        setRSVPSuccess(false);
        localStorage.setItem('rsvpSuccess', 'false');
        console.log(response);
      }
    }
    catch (error) {
      console.log('Post failed: ', JSON.parse(error.response.body))
      setRSVPSuccess(false);
      localStorage.setItem('rsvpSuccess', 'false');
    }
  }

  const selectAllOptions = () => {
    const selectElement = document.getElementById('attending');
    if (selectElement) {
      for (let i = 0; i < selectElement.options.length; i++) {
        selectElement.options[i].selected = true;
      }
      setAttending(Array.from(selectElement.options, option => option.value));
    }
  };

  const deselectAllOptions = () => {
    const selectElement = document.getElementById('attending');
    if (selectElement) {
      for (let i = 0; i < selectElement.options.length; i++) {
        selectElement.options[i].selected = false;
      }
      setAttending([]);
    }
  }

    return (
      <>
      <div id="rsvp">
        <h1>RSVP</h1>
        { rsvpSubmitted == false ?
          <form onSubmit={submitRSVP}>
              <div className="form_row">
                  <label htmlFor="full_name">Full Name:</label>
                  <input 
                    name="full_name" 
                    id="full_name" 
                    value={fullName} 
                    required
                    onChange={(e) => setFullName(e.target.value)}
                  />
              </div>
              <div className="form_row">
                  <label htmlFor="p_full_name">+1 Full Name:</label>
                  <input 
                    name="p_full_name" 
                    id="p_full_name"
                    value={plusOneName} 
                    onChange={(e) => setPlusOneName(e.target.value)}
                  />
              </div>
              <div className="form_row radio">
                <label htmlFor="rsvp">Attending?</label>
                <input type="radio" value="true" name="rsvp" id="rsvpYes" checked={rsvp === "true"} onChange={(e) => setRSVP(e.target.value)} />Yes
                <input type="radio" value="false" name="rsvp" id="rsvpNo" checked={rsvp === "false"} onChange={(e) => setRSVP(e.target.value)} />No
              </div>
              { rsvp === "true" ? (
                <>
                <div className="form_row select">
                    <select
                      name="attending"
                      id="attending"
                      multiple
                      value={attending}
                      required
                      onChange={(e) => setAttending(Array.from(e.target.selectedOptions, option => option.value))}
                    >
                        <option value="ceremony">Ceremony</option>
                        <option value="reception">Reception</option>
                        <option value="camping">Camping</option>
                    </select>
                </div>
                <div className="form_row buttons">
                    <button type="button" onClick={selectAllOptions}>Select All</button>
                    <button type="button" onClick={deselectAllOptions}>Deselect All</button>
                </div>
                <div className="form_row slider">
                    <label htmlFor="num_guests">Number of Guests Attending:</label>
                    <input
                      type="range"
                      name="num_guests"
                      id="num_guests"
                      min={1}
                      max={6}
                      step={1}
                      value={numGuests}
                      onChange={(e) => setNumGuests(e.target.value)}
                    />
                    <div>Value: {numGuests}</div>
                </div>
                <div className="form_row select">
                    <label htmlFor="dietary_reqs">Dietary Requirements:</label>
                    <select 
                      name="dietary_reqs"
                      id="dietary_reqs"
                      multiple
                      value={dietaryReqs}
                      required
                      onChange={(e) => setDietaryReqs(Array.from(e.target.selectedOptions, option => option.value))}
                    >
                        <option value="None">None</option>
                        <option value="Vegan">Vegan</option>
                        <option value="Vegetarian">Vegetarian</option>
                        <option value="DairyFree">Dairy Free</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                { dietaryReqs == "Other" ? (
                <div className="form_row textarea" name="dietary_reqs_textarea">
                    <h4>Please note we will do our best to accommodate any dietary requirements, but this will be up to our caterers.</h4>
                    <label htmlFor="other_dietary_reqs">Please specify:</label>
                    <textarea
                      name="other_dietary_reqs"
                      id="other_dietary_reqs"
                      value={otherDietaryReqs}
                      rows="4"
                      onChange={(e) => setOtherDietaryReqs(e.target.value)}
                    >
                    </textarea>
                </div>
                ) : (<></>)}
                </>
              ) : rsvp === "false" ? (
                <h3>We're sorry you won't be joining us!</h3>
              ) : (
                <></>
              )}

              <div className="form_row buttons">
                  <button type="submit">Submit</button>
                  <button onClick={setDefaultOptions}>Reset</button>
              </div>
          </form>
          :
          <>
          { rsvpSuccess ? 
            <>
            <h2>Thanks for your RSVP!</h2>
            </>
            : 
            <h2>There was an error submitting your RSVP. Please try again or get in touch.</h2>
          }
          <div className="form_row buttons">
            <button onClick={clearRSVPSubmitted}>RSVP again</button>
            <Link className="button" to="/contact">Contact Us</Link> 
          </div>
          </>
        }
      </div>
      </>
    ) 
  };
  
  export default RSVP;